@use "@anarock/ar-common-react/dist/styles/partials" as *;
@import "../invoices/index.module.scss";

:local(.greenText) {
  color: map-get($map: $emerald-color, $key: emerald-400);
}
:local(.redText) {
  color: map-get($map: $red-color, $key: red-400);
}
:local(.tableContainer) {
  margin-top: map-get($map: $scale, $key: 8);
}
:local(.incentiveTableContainer) {
  tbody {
    tr:first-child {
      td {
        padding: 0 !important;
      }
    }
    tr:nth-child(2) {
      td {
        padding: map-get($map: $scale, $key: 16) !important;
        font-weight: 500;
      }
    }
  }
}